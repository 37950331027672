
import { defineComponent, reactive, computed, onBeforeMount } from "vue";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "memo",
  components: {
  },
  props: {
    memoType: String,
    memoTypeId: Number,
    pageSize: Number,
  },
  setup(props) {
    const store = useStore();
    const user = store.getters.currentUser;
    const memos = reactive([] as any);    
    const memoPageSize = props.pageSize ?? 5;
    let page = reactive({pageValue : 1});

    const pagedMemos = computed(()=>{
      return memos
                .filter(data => !search 
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase())
                            || (data.created_by.first_name + " " + data.created_by.last_name).toLowerCase().includes(search.searchValue.toLowerCase()))
                .sort((x, y) => +new Date(y.create_date) - +new Date(x.create_date))
                .slice(memoPageSize * page.pageValue - memoPageSize, memoPageSize * page.pageValue)
     })

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleDelete = (row) => {
        ElMessageBox.confirm('Are you sure to delete this memo?')
        .then(() => {
            ApiService.setHeader();
            ApiService.delete("memo/"+row.id)
                    .then(() => {
                        let index = memos.findIndex(d => d.id === row.id);
                        memos.splice(index,1);   
                    })
                    .catch(({ response }) => {
                        console.log('delete memo error => '+response.data.errors);
                    });
            
        });
    };

    const search = reactive({searchValue : ''});
    const newMemo = reactive({memo : '', user_id : user.id});

    const createMemo = () =>{
        ApiService.setHeader();
        ApiService.post("memo", newMemo as any)
                .then(({data}) =>{
                    data.data['created_by'] = { name : user.name};
                    memos.push(data.data);
                })
                .catch(({ response }) => {
                    console.log('createMemo error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new memo",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });

        newMemo.memo = '';
    }

    const getMemo = async () => {
        let memoTypeUrl = ''
        switch (props.memoType) {
            case 'Student':
                newMemo['student_id']=props.memoTypeId;
                memoTypeUrl = '/student/'+props.memoTypeId;
                break;
            case 'Subject':
                newMemo['subject_id']=props.memoTypeId;
                memoTypeUrl = '/subject/'+props.memoTypeId;
                break;
            case 'Agent':
                newMemo['agent_id']=props.memoTypeId;
                memoTypeUrl = '/agent/'+props.memoTypeId;
                break;
            default:
                break;
        }

        await new Promise((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("memo"+memoTypeUrl)
                .then(({ data }) => {
                    Object.assign(memos,data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    console.log(response);
                    reject(new Error ('getFinanceRecords error => '+response.data.errors));
                });
        });
    }

    onBeforeMount(() => {
        getMemo();
    })

    return {
      memos,
      pagedMemos,
      memoPageSize,
      page,
      search,
      newMemo,
      setPage,
      handleDelete,
      createMemo,
      moment
    };
  },
});
