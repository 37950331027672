
import { defineComponent, onMounted, ref, computed, onBeforeMount, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "edit-class-modal",
  components: {
      Multiselect
  },
  props:{
    subjectId: Number,
    classStudents : [] as any,
    reloadClassStudent : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const formData = ref({
        studentIds : [],
        subject_id : props.subjectId,
        start_date : ''
    });
    const students = ref([] as any);
    const studentsData = computed(() => {
      return students.value != undefined && students.value.length > 0 ? students.value.slice().sort((a,b) => a.student_id.localeCompare(b.student_id))
            .map(x=>{ return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")"}}) :[];
    });

    const studentMultiselect = ref({
        mode: "tags",
        options: studentsData,
        searchable: true,
        createTag: true
    });

    watch(() => props.classStudents, async (cur, prev)=>{
        if(cur !== prev){
            await getStudents();
        }
    }) 

    const resetFormData = () =>{
      formData.value.studentIds = [];
      formData.value.subject_id = props.subjectId;
      formData.value.start_date = '';
    }

    const getStudents = async() => {
        ApiService.setHeader();
        await ApiService.get("student/status/Active")
              .then(({ data }) => {
                  if(props.classStudents && props.classStudents.length > 0){
                      students.value = data.data.filter(student => props.classStudents.filter(cStudent => cStudent.student.id == student.id).length == 0 );
                  }
                  else{
                    students.value = data.data  
                  }
              })
              .catch((error)=> {
                console.log('getStudents error => '+error);
              });
    }

    const formSubmit = () => {
        saveClassStudent(formData.value);
    };

    const saveClassStudent = (data) => {
      ApiService.setHeader();
      ApiService.post("class-student/list/", data)
        .then(() => {
            Swal.fire({
                  text: "created successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    props.reloadClassStudent();
                    resetFormData();
                    let closeButton = document.getElementById('create_class_student_close_button');
                    closeButton?.click();
                });
            
        })
        .catch(({ response }) => {
            Swal.fire({
                  text: "Update failed",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            console.log('handleEdit error => '+response.data.errors);
        });
    };

    onBeforeMount(() =>{
        getStudents();
    })

    return {
      formSubmit,
      moment,
      formData,
      studentMultiselect
    };
  },
});
