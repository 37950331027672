
import { defineComponent, onMounted, ref, computed, onBeforeMount, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "terminate-class-student-modal",
  components: {
  },
  props:{
    studentId: Number,
    subjectId : Number,
    reloadClassStudent : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const terminateDate = ref();

    const saveTerminateDate = () =>{
      if(terminateDate.value){
        ElMessageBox.confirm('Are you sure to terminate the student from this class?')
            .then(() => {
                ApiService.setHeader();
                ApiService.update("class-student/"+props.subjectId, props.studentId! , {end_date : terminateDate.value} as any)
                    .then(async () => {
                        await props.reloadClassStudent();
                        let closeButton = document.getElementById('edit_class_close_button');
                        closeButton?.click();
                    })
                    .catch(({ response }) => {
                        console.log('handleTerminate error => '+response.data.errors);
                    });
            });
      }
    }

    const resetData = () =>{
      terminateDate.value = null;
    }

    const isDisabled = computed(() =>{
      return terminateDate.value ? false : true;
    })

    return {
      terminateDate,
      saveTerminateDate,
      resetData,
      isDisabled
    };
  },
});
