<template>
  <div
    class="modal fade"
    id="modal_create_class_student"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Create class students</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            id="create_class_student_close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
            <div class="flex-row-fluid px-lg-15">
              <el-form
                id="kt_modal_edit_class_form"
                class="form"
                :model="formData"
                ref="formRef"
              >
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Students</span>
                        </label>
                        <Multiselect v-model="formData.studentIds" v-bind="studentMultiselect"></Multiselect>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Start Date</span>
                        </label>
                        <el-form-item prop="start_date">
                          <el-date-picker
                            placeholder="Start date"
                            v-model="formData.start_date"
                            class="w-100"
                            format="MM/DD/YYYY">
                          </el-date-picker>
                        </el-form-item>
                      </div>
                  </div>
                </div>
              <div class="d-flex flex-stack" style="float:right">
                <div>
                  <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click="formSubmit()"
                    >
                    <span class="indicator-label">
                        Submit
                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                        <inline-svg
                            src="media/icons/duotune/arrows/arr064.svg"
                        />
                        </span>
                    </span>
                  </button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, onBeforeMount, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "edit-class-modal",
  components: {
      Multiselect
  },
  props:{
    subjectId: Number,
    classStudents : [] as any,
    reloadClassStudent : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const formData = ref({
        studentIds : [],
        subject_id : props.subjectId,
        start_date : ''
    });
    const students = ref([] as any);
    const studentsData = computed(() => {
      return students.value != undefined && students.value.length > 0 ? students.value.slice().sort((a,b) => a.student_id.localeCompare(b.student_id))
            .map(x=>{ return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")"}}) :[];
    });

    const studentMultiselect = ref({
        mode: "tags",
        options: studentsData,
        searchable: true,
        createTag: true
    });

    watch(() => props.classStudents, async (cur, prev)=>{
        if(cur !== prev){
            await getStudents();
        }
    }) 

    const resetFormData = () =>{
      formData.value.studentIds = [];
      formData.value.subject_id = props.subjectId;
      formData.value.start_date = '';
    }

    const getStudents = async() => {
        ApiService.setHeader();
        await ApiService.get("student/status/Active")
              .then(({ data }) => {
                  if(props.classStudents && props.classStudents.length > 0){
                      students.value = data.data.filter(student => props.classStudents.filter(cStudent => cStudent.student.id == student.id).length == 0 );
                  }
                  else{
                    students.value = data.data  
                  }
              })
              .catch((error)=> {
                console.log('getStudents error => '+error);
              });
    }

    const formSubmit = () => {
        saveClassStudent(formData.value);
    };

    const saveClassStudent = (data) => {
      ApiService.setHeader();
      ApiService.post("class-student/list/", data)
        .then(() => {
            Swal.fire({
                  text: "created successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    props.reloadClassStudent();
                    resetFormData();
                    let closeButton = document.getElementById('create_class_student_close_button');
                    closeButton?.click();
                });
            
        })
        .catch(({ response }) => {
            Swal.fire({
                  text: "Update failed",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            console.log('handleEdit error => '+response.data.errors);
        });
    };

    onBeforeMount(() =>{
        getStudents();
    })

    return {
      formSubmit,
      moment,
      formData,
      studentMultiselect
    };
  },
});
</script>
