<template>
<div
    class="modal fade"
    id="modal_terminate_class_student"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-450px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Terminate Student</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            id="edit_class_close_button"
            @click="resetData"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
            <div class="flex-row-fluid px-lg-15">
              <div class="w-100">
                  <div class="row mb-10">
                    <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Terminate Date</span>
                        </label>
                        <el-date-picker
                          v-model="terminateDate"
                          type="date"
                          class="w-100"
                          format="MM/DD/YYYY">
                        </el-date-picker>
                    </div>
                </div>
              </div>
              <div class="d-flex flex-stack" style="float:right">
                  <div>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary"
                      @click="saveTerminateDate()"
                      :disabled=isDisabled
                      >
                      <span class="indicator-label">
                          Save
                      </span>
                    </button>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, onBeforeMount, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "terminate-class-student-modal",
  components: {
  },
  props:{
    studentId: Number,
    subjectId : Number,
    reloadClassStudent : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const terminateDate = ref();

    const saveTerminateDate = () =>{
      if(terminateDate.value){
        ElMessageBox.confirm('Are you sure to terminate the student from this class?')
            .then(() => {
                ApiService.setHeader();
                ApiService.update("class-student/"+props.subjectId, props.studentId! , {end_date : terminateDate.value} as any)
                    .then(async () => {
                        await props.reloadClassStudent();
                        let closeButton = document.getElementById('edit_class_close_button');
                        closeButton?.click();
                    })
                    .catch(({ response }) => {
                        console.log('handleTerminate error => '+response.data.errors);
                    });
            });
      }
    }

    const resetData = () =>{
      terminateDate.value = null;
    }

    const isDisabled = computed(() =>{
      return terminateDate.value ? false : true;
    })

    return {
      terminateDate,
      saveTerminateDate,
      resetData,
      isDisabled
    };
  },
});
</script>