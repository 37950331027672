<template>
    <div class="card mb-5 mb-xl-10" v-if="subject">
            <div class="card-header border-0 cursor-pointer" style="min-height: 50px;">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">Basic Information</h3>
                </div>
            </div>
            <div class="card-body border-top p-9 row mb-6" style="padding-top: 2.25rem !important; padding-bottom: 0px !important; margin-bottom: 5px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Name
                </label>

                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row">
                            {{subject.name}}({{subject.code}})
                        </div>
                    </div>
                </div>
            </div>     
            <div class="card-body p-9 row mb-6" style="padding-top: 0px !important; padding-bottom: 0px !important; margin-bottom: 5px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Period
                </label>

                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row" v-if="subject.session">
                            {{moment(subject.session.level.program.semester.start_date).format('MM/DD/YYYY')}} ~ 
                            {{moment(subject.session.level.program.semester.end_date).format('MM/DD/YYYY')}}
                        </div>
                    </div>
                </div>
            </div>  
            <div class="card-body p-9 row mb-6" style="padding-top: 0px !important; padding-bottom: 0px !important; margin-bottom: 5px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Time Range
                </label>

                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row">
                            {{subject.start_time}} ~ {{subject.end_time}}
                        </div>
                    </div>
                </div>
            </div>            
            <div class="card-body p-9 row mb-6" style="padding-top: 0px !important;padding-bottom: 0px !important;margin-bottom: 5px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Hours
                </label>

                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row">
                            {{subject.hours}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-9 row mb-6" style="padding-top: 0px !important;padding-bottom: 0px !important;margin-bottom: 5px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Instructor
                </label>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row">
                            <span v-if="subject.instructor">{{subject.instructor.first_name}} {{subject.instructor.last_name}}</span>
                            <span v-else>Not assigned</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-9 row mb-6" style="padding-top: 0px !important;padding-bottom: 0px !important;margin-bottom: 5px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Class days
                </label>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row">
                            <span v-if="subject.class_days">{{daysOfWeek.filter(r=> subject.class_days.includes(r.value)).map(x=>x.label).join(", ")}}</span>
                            <span v-else>N/A</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-9 row mb-6" style="padding-top: 0px !important;padding-bottom: 0px !important;">
                <label class="col-lg-4 fw-bold fs-6">
                    Skill Area
                </label>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="fv-row">
                            <span v-if="subject.skill_area">{{subject.skill_area.replace(';',', ')}}</span>
                            <span v-else>N/A</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer" style="min-height: 50px;">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Students</h3>
                <p class="m-0" style="padding-left: 10px;color: #706e6e;font-size: 1.150rem;" v-if="classStudents.value">total : {{classStudents.value.length}}</p>
            </div>
        </div>

        <div class="card-body border-top p-9">
            <div class="me-4" style="float:left;">
                <button
                    class="btn btn-sm btn-success"
                    id="student_download_button"
                    @click="downloadStudents"
                >
                    Download
                </button>
            </div>
            <div class="me-4" style="float :right;">
                <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_create_class_student"
                    id="create_class_student"
                >
                    Add Student
                </a>
            </div>
            <el-table :data="classStudents.value" style="width: 100%" :header-row-style="{textAlign: 'center'}">
                <el-table-column prop="student.student_id" label="Student Id" width="180" sortable ></el-table-column>
                <el-table-column prop="name" label="name">
                    <template v-slot="scope">
                        <label> {{scope.row.student.first_name}} {{scope.row.student.last_name}}</label>
                    </template>
                </el-table-column>
                <el-table-column prop="start_date" label="Start Date" width="180">
                    <template v-slot="scope">          
                        {{moment(scope.row.start_date).format('MM/DD/YYYY')}}
                    </template>
                </el-table-column>      
                <el-table-column prop="end_date" label="End Date" width="180">
                    <template v-slot="scope">          
                        {{scope.row.end_date ? moment(scope.row.end_date).format('MM/DD/YYYY') : ''}}
                    </template>
                </el-table-column> 
                <el-table-column prop="operation" label="" align="right" width="200">
                    <template v-slot="scope">
                        <button v-if="!scope.row.end_date" class="btn btn-sm btn-success" title="Delete" 
                        @click="handleTerminate(scope.row)" style="margin-right:5px;">Terminate</button>
                        <button v-else class="btn btn-sm btn-warning" title="Delete" 
                        @click="handleReactivate(scope.row)" style="margin-right:5px;">Reactivate</button>         
                        <button class="btn btn-sm btn-danger" title="Delete" 
                        @click="handleDelete(scope.row)">Delete</button>
                    </template></el-table-column>
            </el-table>
        </div>
    </div>

    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer" style="min-height: 50px;">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Assessment</h3>
            </div>
        </div>

        <div class="card-body border-top p-9">
            <div>
                <el-table :data="assessments">
                    <el-table-column prop="type.name" label="Type"></el-table-column>
                    <el-table-column prop="title" label="Title"></el-table-column>
                    <el-table-column prop="assessment_date" label="Assessment Date">
                        <template v-slot="scope">
                            <span v-if="moment(scope.row.assessment_start_date).format('MM/DD/YYYY') === moment(scope.row.assessment_end_date).format('MM/DD/YYYY')">
                                {{moment(scope.row.assessment_start_date).format('MM/DD/YYYY')}}
                            </span>
                            <sapn v-else>
                                {{moment(scope.row.assessment_start_date).format('MM/DD/YYYY')}} ~ {{moment(scope.row.assessment_end_date).format('MM/DD/YYYY')}}
                            </sapn> 
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer" style="min-height: 50px;">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Memo</h3>
            </div>
        </div>

        <div class="card-body border-top p-9">
            <MemoWidget 
                memoType="Subject"
                v-bind:memoTypeId="subjectId"
                pageSize=5 />
        </div>
    </div>
    <a
        href="#"
        class="btn btn-sm btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#modal_terminate_class_student"
        id="terminate_class_student"
        style="display:none;"
    />
    <EditClassStudentModal
        v-bind:reloadClassStudent="getClassStudent"
        v-bind:subjectId="subjectId"
        v-bind:classStudents="classStudents.value">
    </EditClassStudentModal>
    <TerminateClassStudent
        v-bind:reloadClassStudent="getClassStudent"
        v-bind:subjectId="subjectId"
        v-bind:studentId="selectedStudentId">
    </TerminateClassStudent>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, onBeforeMount, reactive } from "vue";
import { useRoute } from 'vue-router'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MemoWidget from "@/views/widgets/Memo.vue"
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import EditClassStudentModal from "@/views/academics/modals/EditClassStudentModal.vue"
import TerminateClassStudent from "@/views/academics/modals/TerminateClassStudentModal.vue"
import { ElMessageBox } from "element-plus";
import CommonService from "@/core/services/CommonService";

export default defineComponent({
    name: "agent-detail-modal",
    components: {   
        MemoWidget,
        EditClassStudentModal,
        TerminateClassStudent
    },
    setup() {
        const route = useRoute();
        const subjectId = route.params.id; 
        const subject = reactive({} as any);
        const classStudents = reactive([] as any);
        const selectedStudentId = ref(Number);
        const assessments = ref([] as any);
        const daysOfWeek = [{label :'Sun', value : 0}, 
                        {label :'Mon', value : 1},
                        {label :'Tue', value : 2},
                        {label :'Wed', value : 3},
                        {label :'Thu', value : 4},
                        {label :'Fri', value : 5},
                        {label :'Sat', value : 6}];

        const getSubject = async () => {
            return new Promise((resolve, reject) => {    
                ApiService.setHeader();        
                ApiService.get("class/subject/"+subjectId)
                    .then(({ data }) => {
                        Object.assign(subject,data.data);
                        resolve(data.data);
                    })
                    .catch((error) => {
                        reject(new Error ('getSubject error => '+error));
                    });
            });
        }
        
        const getClassStudent = () : any => {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get("class-student/students/"+subjectId)
                .then(({ data }) => {
                    classStudents.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
            });
        }

        const getAssessments = () : any => {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get("assessment/subject/"+subjectId)
                .then(({ data }) => {
                    assessments.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
            });
        }

        const loadPage = () =>{
            getSubject().then(async()=>{
                await getClassStudent();
                await getAssessments();
            });
        }

        const handleDelete = (row) =>{
            ElMessageBox.confirm('Are you sure to delete the student from this class?')
                .then(() => {
                    ApiService.setHeader();
                    ApiService.delete("class-student/"+subjectId+"/"+row.student_id)
                        .then(async () => {
                            await getClassStudent();
                        })
                        .catch(({ response }) => {
                            console.log('handleDelete error => '+response.data.errors);
                        });
                });
        }

        const handleTerminate = (row) =>{
            selectedStudentId.value = row.student_id;
            let terminateButton = document.getElementById('terminate_class_student');
            terminateButton?.click();
        }

        const handleReactivate = (row) =>{
            ElMessageBox.confirm('Are you sure to reactivate this terminated student?')
                .then(() => {
                    ApiService.setHeader();
                    ApiService.update("class-student/"+row.subject_id, row.student_id , {end_date : ''} as any)
                        .then(async () => {
                            await getClassStudent();
                        })
                        .catch(({ response }) => {
                            console.log('handleDelete error => '+response.data.errors);
                        });
                });
        }

        const downloadStudents = ()=>{
            let currentStudentList = classStudents.value.map(x=>{ return { 
                StudentId: x.student.student_id, 
                Name : x.student.first_name+' ' +x.student.last_name,
                StartDate : moment(x.start_date).format('MM/DD/YYYY'),       
            };});
            let today = new Date();
            let fileName = subject.code+' Student List '+ today.toLocaleString();
            CommonService.exportToCsv(fileName, currentStudentList);
        }

        onBeforeMount(async () => {
            await loadPage();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Class Details", ["Academics", "Class"]);
        });

        return {
            subjectId,
            moment,
            subject,
            classStudents,
            handleDelete,
            handleTerminate,
            getClassStudent,
            assessments,
            daysOfWeek,
            handleReactivate,
            selectedStudentId,
            downloadStudents
        };
    },
});
</script>