
import { defineComponent, onMounted, ref, onBeforeMount, reactive } from "vue";
import { useRoute } from 'vue-router'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MemoWidget from "@/views/widgets/Memo.vue"
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import EditClassStudentModal from "@/views/academics/modals/EditClassStudentModal.vue"
import TerminateClassStudent from "@/views/academics/modals/TerminateClassStudentModal.vue"
import { ElMessageBox } from "element-plus";
import CommonService from "@/core/services/CommonService";

export default defineComponent({
    name: "agent-detail-modal",
    components: {   
        MemoWidget,
        EditClassStudentModal,
        TerminateClassStudent
    },
    setup() {
        const route = useRoute();
        const subjectId = route.params.id; 
        const subject = reactive({} as any);
        const classStudents = reactive([] as any);
        const selectedStudentId = ref(Number);
        const assessments = ref([] as any);
        const daysOfWeek = [{label :'Sun', value : 0}, 
                        {label :'Mon', value : 1},
                        {label :'Tue', value : 2},
                        {label :'Wed', value : 3},
                        {label :'Thu', value : 4},
                        {label :'Fri', value : 5},
                        {label :'Sat', value : 6}];

        const getSubject = async () => {
            return new Promise((resolve, reject) => {    
                ApiService.setHeader();        
                ApiService.get("class/subject/"+subjectId)
                    .then(({ data }) => {
                        Object.assign(subject,data.data);
                        resolve(data.data);
                    })
                    .catch((error) => {
                        reject(new Error ('getSubject error => '+error));
                    });
            });
        }
        
        const getClassStudent = () : any => {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get("class-student/students/"+subjectId)
                .then(({ data }) => {
                    classStudents.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
            });
        }

        const getAssessments = () : any => {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get("assessment/subject/"+subjectId)
                .then(({ data }) => {
                    assessments.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
            });
        }

        const loadPage = () =>{
            getSubject().then(async()=>{
                await getClassStudent();
                await getAssessments();
            });
        }

        const handleDelete = (row) =>{
            ElMessageBox.confirm('Are you sure to delete the student from this class?')
                .then(() => {
                    ApiService.setHeader();
                    ApiService.delete("class-student/"+subjectId+"/"+row.student_id)
                        .then(async () => {
                            await getClassStudent();
                        })
                        .catch(({ response }) => {
                            console.log('handleDelete error => '+response.data.errors);
                        });
                });
        }

        const handleTerminate = (row) =>{
            selectedStudentId.value = row.student_id;
            let terminateButton = document.getElementById('terminate_class_student');
            terminateButton?.click();
        }

        const handleReactivate = (row) =>{
            ElMessageBox.confirm('Are you sure to reactivate this terminated student?')
                .then(() => {
                    ApiService.setHeader();
                    ApiService.update("class-student/"+row.subject_id, row.student_id , {end_date : ''} as any)
                        .then(async () => {
                            await getClassStudent();
                        })
                        .catch(({ response }) => {
                            console.log('handleDelete error => '+response.data.errors);
                        });
                });
        }

        const downloadStudents = ()=>{
            let currentStudentList = classStudents.value.map(x=>{ return { 
                StudentId: x.student.student_id, 
                Name : x.student.first_name+' ' +x.student.last_name,
                StartDate : moment(x.start_date).format('MM/DD/YYYY'),       
            };});
            let today = new Date();
            let fileName = subject.code+' Student List '+ today.toLocaleString();
            CommonService.exportToCsv(fileName, currentStudentList);
        }

        onBeforeMount(async () => {
            await loadPage();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Class Details", ["Academics", "Class"]);
        });

        return {
            subjectId,
            moment,
            subject,
            classStudents,
            handleDelete,
            handleTerminate,
            getClassStudent,
            assessments,
            daysOfWeek,
            handleReactivate,
            selectedStudentId,
            downloadStudents
        };
    },
});
